import React, { useState } from "react"
import { Document, Page } from "react-pdf"
import { Container, SEO } from "../components"
import resume from "../resume/resume.pdf"

const Resume = () => {
  const [numOfPages, setNumOfPages] = useState(null)

  function onDocumentLoadSuccess({ numPages }) {
    setNumOfPages(numPages)
  }

  return (
    <Container title="Resume">
      <SEO />
      <h1>Resume</h1>
      <div className="resume">
        <div className="document">
          <Document file={resume} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numOfPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </div>
    </Container>
  )
}

export default Resume
